
.slick-prev:before, .slick-next:before {
    color: black!important;
}

.slick-dots li.slick-active button:before {
    color: #820F65!important;
}

/*.slick-prev:before, .slick-next:before {*/
/*    content: none!important;*/
/*}*/

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    color: inherit!important;
}